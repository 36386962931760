
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Util from "@/common/ts/Util";
import KTAgree from "@/views/auth/agree/Agree.vue";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
    KTAgree,
  },
  setup() {
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const mobile = ref(""); //以后再优化
    const gbnTime = ref(0);
    const agree = ref("");

    const showTime = () => {
      gbnTime.value++;
      if (gbnTime.value <= 60) {
        setTimeout(() => {
          showTime();
        }, 1000);
      } else {
        gbnTime.value = 0;
      }
    };

    const kt_agree = ref(null);
    const handleAgree = (type) => {
      (kt_agree.value as any).initData(type);
    };

    //Create form validation object
    const registration = Yup.object().shape({
      mobile: Yup.string().required("请输入手机号码"),
      auth_code: Yup.string().required("请输入验证码"),
      user_password: Yup.string()
        .min(6, "密码长度至少6位")
        .required("请输入登录密码"),
      confirm_password: Yup.string()
        .min(6, "密码长度至少6位")
        .required("请输入确认密码")
        .oneOf([Yup.ref("user_password"), null], "密码输入不一致"),
    });

    //Form submit function
    const onSubmitRegister = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      ApiService.post("MemberAuth/SignUp", values)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
            router.push({ name: "sign-in" });
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    const handleAuthCodeSend = () => {
      if (mobile.value) {
        if (!Util.isMobile(mobile.value)) {
          Toast.warning("手机号码格式不正确");
          return;
        }
      } else {
        Toast.warning("请输入手机号码");
        return;
      }

      ApiService.post("MemberAuth/AuthCodeSend", {
        mobile: mobile.value,
        auth_type: "00",
      } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
            showTime();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    };

    return {
      onSubmitRegister,
      registration,
      submitButton,
      handleAuthCodeSend,
      mobile,
      gbnTime,
      agree,
      handleAgree,
      kt_agree,
    };
  },
});
