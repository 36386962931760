
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "invite-friends-modal",
  components: {},
  data() {
    return {
      agree: {},
    };
  },
  methods: {
    initData(type) {
      ApiService.post("Agree/AgreeDetail", { type } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            if (data.data) {
              this.agree = data.data;
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
  },
});
